<template>
  <div class="product-details">
    <search-com  @search-btn="searchBtn"></search-com>
    <div class="productDetails">
      <div class="nav_box">
        <welfare-breadcrumb :itemBreadcrumb="itemBreadcrumb"></welfare-breadcrumb>
      </div>
      <div class="productDetails_box">
        <div class="product_img">
          <img v-if="productDetails.commodityPictures" :src="productDetails.commodityPictures" />
        </div>
        <div class="product_info">
            <div class="info_header">{{productDetails.commodityName}}</div>
            <div class="info_price">
                售价<span class="red">¥<span class="fz22">{{productDetails.enterprisePrice}}</span></span>
            </div>
            <div class="buy_num">
                购买数量
                <el-input-number size="middle" v-model.number="num" :min="1" @change="handleChange"  @input.native="preventPoint"></el-input-number>
            </div>
            <div class="buy_type">
                  购买类型<i class="el-icon-warning-outline" @click="openTips"></i>
                  <div class="buy_type_radio">
                      <el-radio v-model="radio" label="2" border>企业集采</el-radio>
                      <el-radio v-model="radio" label="3" border>员工自领</el-radio>
                  </div>
            </div>
            <div class="cart">
              <div>
                <el-button style="color:#fff;" v-if="productIsActive" type="danger" @click="addCarBtn">
                    <i class="el-icon-shopping-cart-1"></i>
                    加入购物车
                </el-button>
                <el-button v-else type="info" plain disabled>
                  礼包已下架
                </el-button>
              </div>
              <span class="buy_tips">加入购物车，去购物车下单</span>
            </div>
        </div>
      </div>
    <div class="line_box"></div>
      <!-- 配送服务Distribution service -->
      <div class="distribution_service_box">
        <h1 class="header_title">配送服务：</h1>
        <div class="serve_msg_box">
          <div v-if="productDetails.commodityDistribution && productDetails.commodityDistribution.Distribution !== 0">包邮说明：{{productDetails.commodityDistribution.Distribution}}</div>
          <div v-if="productDetails.commodityDistribution && productDetails.commodityDistribution.ExcludeArea.length !== 0">不包邮说明：{{productDetails.commodityDistribution.ExcludeArea}}</div>
          <div>退换货：{{productDetails.changeorRefund}}</div>
          <div>退换条件：{{productDetails.returnConditions}}</div>
        </div>
      </div>
      <div class="distribution_service_box">
        <h1 class="header_title">商品详情：</h1>
        <p v-html="productDetails.commodityDescription"></p>
      </div>
    </div>
  </div>
</template>

<script>
import SearchCom from '@/components/welfareMallComp/SearchCom';
import WelfareBreadcrumb from '@/components/welfareMallComp/WelfareBreadcrumb';
import { getProductListShopDetails, getReqLoginAddCart } from "@/network/welfareMall_api";
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'productDetails',
  components: {
    WelfareBreadcrumb,
    SearchCom,
  },
  data() {
    return {
      itemBreadcrumb: [
        {
          name: '福利商城',
          to: '/welfareHome'
        },
       {
          name: '',
          to: '/productList'
        },
        {
          name: '商品详情'
        },
      ],
      pagination: {
        pageIndex: 1,
        limit: 10,
        total: 100
      },
      num: '',
      mealPrice: '',
      productName: '',
      productDetails: {},//套餐信息
      productIsActive: true,//套餐是否有效 默认有效
      radio:'',
    };
  },
  computed:{
    // 映射
    ...mapState(['useInfo'])
  },
  watch:{
  },
  created() {
    // console.log(this.$route.query.id)
    if(this.$route.query.id){
      let id = this.$route.query.id;
      this.getProductDetails(id);
      sessionStorage.setItem('productList_commodityID',id);
    }else if(this.$route.params.commodityID){
      this.getProductDetails(this.$route.params.commodityID);
    }else if(sessionStorage.getItem('productList_commodityID')){
      this.getProductDetails(sessionStorage.getItem('productList_commodityID'));
    }
    // 判断从router来吗？ 
    setTimeout(()=>{
      document.title = this.productDetails.commodityName+'-壹人事企业商城';
      //this.itemBreadcrumb[2].name = this.productDetails.commodityName;
    },1000)
  },
  
  methods: {
    // 映射mapMutations里的方法
    ...mapMutations(['saveCartNum']),
    // 获取商品详情
    async getProductDetails (id){
        let res = await getProductListShopDetails(id); 
        if(res.isSuccess){
            let productDetails =  res.data;
            JSON.parse(JSON.stringify(productDetails))
            productDetails.commodityDistribution = JSON.parse(productDetails.commodityDistribution)
            let excludeArea = productDetails.commodityDistribution.ExcludeArea;
            if(excludeArea.length == 0 ){
                productDetails.commodityDistribution.ExcludeArea = []
            }else{
                productDetails.commodityDistribution.ExcludeArea = productDetails.commodityDistribution.ExcludeArea.join(',') 
            }
            productDetails.commodityPictures = productDetails.commodityPictures.split(';')[0]
            this.productDetails = productDetails;
             //获取商品类别
            this.itemBreadcrumb[1].name = productDetails.categoryName;

        }else{
            this.$message.error(res.msg);
        }
    },
    handleChange(){},
    // 计数器 只输入整数
    preventPoint(e) {
        this.$message.closeAll();
        // 验证是否是纯数字
        let isNumber = /^\d*$/.test(e.target.value);
        // 过滤非数字
        e.target.value = e.target.value.replace(/\D/g, "");
        if (!isNumber || e.target.value < 0) {
          layer.msg("只能输入正整数");
        }
        e.target.value = (e.target.value >= 0 && e.target.value.match(/^\d*/g)[0]) || 1;
    },
    async addCarBtn(){
      var myCartList = [];
      var cartObj ={};
      cartObj.mealID = this.productDetails.commodityID;
      cartObj.mealCode = this.productDetails.commodityCode;
      cartObj.mealName = this.productDetails.commodityName;
      cartObj.mealPrice = this.productDetails.enterprisePrice;
      cartObj.mealUrl = this.productDetails.commodityPictures;
      cartObj.mealCount = this.num;
      cartObj.isActive = true;// 初始化当前套餐显示为有效
      cartObj.amount = this.productDetails.enterprisePrice * this.num;//金额
      cartObj.orderType = Number(this.radio);//类型
      if(cartObj.orderType == 0){
        this.$message({
          message: '请选择购买类型！',
          type: 'warning',
          showClose: true,
          duration:1000,
        });
        return
      }
      // 判断是否已在缓存中添加有购物车
      if(sessionStorage.getItem('myCartList') && !this.useInfo.privateUserID){
        myCartList = JSON.parse(sessionStorage.getItem('myCartList'));
        // 此时是未登录状态添加进缓存
        // 判断是否有该商品  无 则push缓存 反之
        var boo = myCartList.some((item)=>{
          return item.mealID === cartObj.mealID;
        })
        //判断商品购买类型
        var type = myCartList.some((item)=>{
            return item.orderType === cartObj.orderType;
        })
        if(!boo){
          myCartList.push(cartObj);
        }else if(!type){
          myCartList.push(cartObj);
        }else{
          // 循环判断缓存中是否有改同样套餐
          myCartList.forEach((item)=>{
            // 如果有 数量相加
            if(item.mealID === cartObj.mealID && item.orderType === cartObj.orderType){
              item.mealCount += cartObj.mealCount;
              item.amount = (item.mealCount * item.mealPrice);
            }
          })
        }
        sessionStorage.setItem('myCartList', JSON.stringify(myCartList));
        this.saveCartNum(myCartList.length);
        this.$message.success('加入购物车成功');
        // 判断是否是登录状态
      }else if(!this.useInfo.privateUserID){
        myCartList.push(cartObj);
        sessionStorage.setItem('myCartList', JSON.stringify(myCartList));
        this.saveCartNum(myCartList.length);
        this.$message.success('加入购物车成功');
        // 已登录状态
      }else if(this.useInfo.privateUserID){
        const {mealID, mealCount,orderType } = cartObj;
        // 加入购物车调登录的加入接口
        var res = await getReqLoginAddCart({
          mealID,
          mealCount,
          OrderType:orderType
        });
        if(res.isSuccess){
          this.$message.success(res.msg);
          // 在vuex中调用获取当前登录用户的购物车数量
          this.$store.dispatch('getLoginCartNum');
        }else{
          this.$message.error(res.msg);
        }
      }
    },
    
    // 页面条数发生改变
    handleSizeChange(val){
      
    },
    //页面页码发生改变 
    handleCurrentChange(val){
      
    },
    openTips(){
        this.$alert(`企业集采：下单后送货至指定地址<br/>
                     <p style="margin-top:5px;">员工自领：下单后发放至员工自行领取（<a href="https://www.1renshi.com/News/60182.html" style="color:#0089CD;">查看员工兑换流程</a>）</p>`,'',{
            showConfirmButton:false,
            dangerouslyUseHTMLString:true,
        })
    }
  },
  destroyed() {

    // 清除cookie welfareMall_goodId
    // document.cookie = "username=welfareMall_goodId; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
  },
  // 点击搜索
  searchBtn(val){
    if(!val){
      return this.$message.info('请输入礼包名称');
    }
    document.cookie = "vueRouter="+ '/welfareSearchResult';
    this.$router.push({
      name: 'WelfareSearchResult',
      params: {
        value: val
      }
    })
  },
};
</script>
<style lang="less" scoped>
.product-details{
  overflow-x: hidden;
}
.productDetails{
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 80px;
  // 商品信息
  .productDetails_box{
    width: 1200px;
    margin: 0 auto;
    margin-top: 24px;
    display: flex;
    .product_img{
        width: 387px;
        height: 387px;
      img{
        width: 387px;
        height: 387px;
      }
    }
    // 商品info
    .product_info{
      margin-left: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .info_header{
        width: 796px;
        height: 48px;
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        color: #333333;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        //text-overflow:ellipsis;
        //white-space: nowrap;
      }
      .info_price{
        width: 789px;
        height: 62px;
        line-height: 62px;
        color: #999999;
        font-size: 16px;
        padding-left: 24px;
        background: #F4F7FC;
        .red{
            margin-left: 12px;
            color: #F5222D;
            .fz22{
                font-size: 22px;
                font-weight: bold;
                margin-left: 5px;
            }
        }
      }
      .buy_num{
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        div{
            margin-left: 30px;
        }
      }
      .buy_type{
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        i{
            display: inline-block;
            margin-left: 2px;
        }
        i:hover{
            cursor: pointer;
            color:#0089cd;
        }
        .buy_type_radio{
            display: inline-block;
            margin-left: 16px;
        }
      }
      .tag{
          padding: 25px 0;
          .tag_info{
            font-size: 14px;
            width: 96px;
            border: 1px solid #BAE7FF;
            border-radius: 4px;
            background: #BAE7FF;
            color: #1890FF;
            padding: 8px;
          }
      }
      .cart{
        display: inline-flex;
        align-items: center;
        .buy_tips{
            margin-left: 8px;
            color:#999999;
            font-size: 14px;
        }
      }
      
    }
  }
  // 员工可兑换。。。
  .conversion_box{
    width: 1200px;
    margin: 0 auto;
    margin-top: 40px;
    h1{
      
      font-size: 18px;
    }
  }
   //分割线
  .line_box{
    border: 1px solid #ccc;
    margin: 30px 0;
  }
  // 员工可兑换。。。
  .distribution_service_box{
    // margin-top: 40px;
    .header_title{
      color: #333333;
      font-size: 16px;
      margin-bottom: 16px;
      font-weight: 700;
    }
    .serve_msg_box{
      color: #666666;
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 20px;
    }
  }
}
</style>
